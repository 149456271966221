<template>
  <div class="assessBox">
    <div class="app-container">
      <div class="titlebox">
        <el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
        <div class="title">{{title}}</div>
      </div>
      <div class="viewbox" id="result">
        <div class="baseWarp">
          <div class="baseBox">
            <div>
              <label>规划名称：</label>
              {{detailData.name}}
              <span style="padding:0 10px;"></span>
              <el-button
                v-for=" (item,i) in btnType "
                :key="i"
                :type="btnTypeColor(detailData.statusName)"
                v-show="btnTypeText(detailData.status,item.value)"
                size="mini"
              >{{detailData.statusName}}</el-button>
            </div>
            <div>
              <label>一级部门：</label>
              {{detailData.orgPidName}}
            </div>
            <div>
              <label>部门人数：</label>
              {{departPeopleCount}}
            </div>
          </div>
          <div class="baseBox">
            <div>
              <label>起止时间：</label>
              <i class="el-icon-date icon-time"></i>
              {{detailData.planStartTime}} 至 &nbsp;{{detailData.planEndTime}}
            </div>
            <div>
              <label>二级部门：</label>
              {{detailData.orgIdName}}
            </div>
            <div>
              <!-- <label>岗位：</label>前端开发 -->
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="information">
          <div class="title-icon">基本信息</div>
          <div class="w">
            <InfoPanel
              v-for="(item,i) in info"
              :key="i"
              :info="item"
              :educationOptions="educationOptions"
            />
          </div>
          <!-- <div class="resultTitle">java工程师岗位信息</div>
          <div class="resultWrap">
            <div class="baseWarp">
              <div class="baseBox">
                <div>
                  <label>需求岗位：</label>java工程师
                </div>
                <div>
                  <label>部门岗位人数：</label>20
                </div>
                <div>
                  <label>需求职级：</label>10
                </div>
                <div>
                  <label>需求专业：</label>计算机与科学
                </div>
                <div>
                  <label>其他需求：</label>其他
                </div>
              </div>
              <div class="baseBox">
                <div>
                  <label>岗位现有人力：</label>20
                </div>
                <div>
                  <label>需求数量：</label>12
                </div>
                <div>
                  <label>截止时间：</label>2019-12-20
                </div>
              </div>
            </div>
            <div class="skill">
              <label>技能需求：</label>
              <p>结果详情</p>
            </div>
            <div class="skill">
              <label>需求原因：</label>
              <p>其他</p>
            </div>
            <div class="skill">
              <label>岗位说明：</label>
              <p>洽谈</p>
            </div>
          </div>-->
        </div>

        <el-divider></el-divider>
        <!-- 附件说明 -->
        <div class="enclosure">
          <div class="title-icon">岗位说明书附件</div>
          <div class="content">
            <label>岗位说明书附件：</label>
            <div>
              <div class="book" v-for="(val,index) in enclosureList" :key="index">
                <doc-file
                  v-for="(item,i) in val"
                  :key="i"
                  :file-name="item.name + ' ('+Math.ceil((Number(item.size)/(1024*1024))*100)/100+'M)'"
                  :url="item.url"
                >岗位说明书模板.doc(1.8M)</doc-file>
              </div>
            </div>
          </div>
        </div>
        <el-divider class="lastone"></el-divider>
        <!-- 审批信息 -->
        <div class="approver">
          <div class="title-icon">审批信息</div>
          <div class="content w">
            <div class="cont_t">
              <div class="cont-title">
                <label>审批人：</label>
              </div>
              <div class="cont-content">
                <div class="go">
                  <span v-for="(item,i) in detailData.approvalUserIds" :key="i">{{item.name}}</span>
                </div>
                <div class="out">
                  <el-radio-group v-model="approverRadio">
                    <el-radio
                      v-for="(status, i) in statuses"
                      :key="i"
                      v-show="canalShow(detailData.status,detailData.statusName,status)"
                      :label="status.value"
                      @click.native.stop="cancelBtn(detailData.status,detailData)"
                    >{{ status.label }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InfoPanel from '../components/InfoPanel';
import {
  planDetatil,
  getPositionNum,
  updateRecruitPlan
} from '@/api/manpowerplanning';
import DocFile from '../../../components/DocFile';
import { getEducationOptions } from '@/utils/dict';
import { findTreeElement } from '../const';
import { queryDepartment } from '@/api/workbench';
export default {
  components: {
    InfoPanel,
    DocFile
  },
  data() {
    return {
      detailData: {},
      id: '',
      title: '人才规划结果详情',
      approverRadio: null,
      statuses: [
        // {
        //   label: "草稿",
        //   value: 0
        // },
        // {
        //   label: "发布",
        //   value: 3
        // },
        {
          label: '取消',
          value: 4
        }
      ],
      info: {},
      enclosureList: [],
      btnType: [
        { type: 'primary', value: 2 },
        { type: 'danger', value: 4 },
        { type: 'primary', value: 0 },
        { type: 'success', value: 1 },
        { type: 'danger', value: 3 }
      ],
      departmentOptions: null,
      departPeopleCount: null, // 部门人数
      educationOptions: [],
      cancelText: 'updateRecruitPlan'
    };
  },

  created() {},
  mounted() {
    this.$nextTick(function() {
      this.id = this.$route.query.id;
      this.getDetail();
      this.educationOptions = getEducationOptions();
    });
  },
  methods: {
    cancelBtn(status, detailData) {
      // 点击取消
      // console.log(status,detailData)
      if (
        (status === 3 &&
          detailData.statusName == '已发布' &&
          this.cancelText) ||
        (status === 2 && detailData.statusName == '发布中' && this.cancelText)
      ) {
        // console.log(123);
        this.cancelText = '';

        this.$confirm('此操作将取消该计划, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            // this.$message({
            //   type: "success",
            //   message: "删除成功!"
            // });
            let {
              id,
              name,
              orgPid,
              orgId,
              status,
              planStartTime,
              planEndTime,
              basicRequestList,
              accessoryInfos,
              approvalUserId
            } = { ...detailData };

            accessoryInfos = accessoryInfos.map((v) => JSON.stringify(v));
            status = 4;
            updateRecruitPlan({
              id,
              name,
              orgPid,
              orgId,
              status,
              planStartTime,
              planEndTime,
              basicRequestList,
              accessoryInfos,
              approvalUserId
            }).then((res) => {
              if (res) {
                this.$router.push({
                  name: 'mpPlan'
                });
              }
              this.approverRadio = status;
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
            this.cancelText = 'updateRecruitPlan';
            this.approverRadio = null;
          });
        //
      }
    },
    canalShow(statusNum, statusName, status) {
      if (statusNum && statusName) {
        // console.log(
        //   statusNum,
        //   statusName,
        //   status,
        //   statusName == "发布中",
        //   statusNum == status.value,
        //   statusNum == 2
        // );
        if (statusName == '已发布' && status.value != '4') {
          return false;
        }
        if (statusName == '发布中' && statusNum !== 2) {
          return false;
        }
      }
      // if (statusName != "已发布" && status.value == "4") {
      //   return false;
      // }
      if (statusName != '发布中' && statusName != '已发布') {
        return false;
      }
      return true;
    },
    btnTypeColor(type) {
      // console.log(type);
      if (type == '已取消') {
        return 'danger';
      }
      if (type == '不通过') {
        return 'danger';
      }
      if (type == '已发布') {
        return 'success';
      }
      return 'primary';
    },
    btnTypeText(status, val) {
      // console.log(status,val)
      if (status == val) {
        return true;
      }
      return false;
    },
    async getDetail() {
      const params = {
        id: this.$route.query.id
      };

      const res = await planDetatil(params);

      if (res) {
        this.detailData = res;
        // console.log(res);
        this.enclosureList = res.accessoryInfos && res.accessoryInfos;
        this.approverRadio = res.status;
      }

      if (res.orgId && res.basicRequestList) {
        let num;

        res.basicRequestList.forEach(async (v, i) => {
          num = await getPositionNum({
            jobId: v.jobId,
            orgId: res.orgId
          });
          v.depCount = num.depCount;
          v.sumCount = num.sumCount;
          this.info =
            res.basicRequestList[i] &&
            res.basicRequestList[res.basicRequestList.length - 1].sumCount &&
            res.basicRequestList;
        });
        // console.log(this.info);
        /*  */
        // getPositionNum({
        //   jobId: res.basicRequestList[0].jobId,
        //   orgId: res.orgId
        // }).then(num => {
        //   console.log(num);
        //   res.basicRequestList[0].depCount = num.depCount;
        //   res.basicRequestList[0].sumCount = num.sumCount;
        //   this.info = res.basicRequestList;
        // });
      } else {
        res.basicRequestList.forEach(async (v, i) => {
          // num = await getPositionNum({
          //   jobId: v.jobId,
          //   orgId: res.orgId
          // });
          // v.depCount = num.depCount;
          // v.sumCount = num.sumCount;
          this.info =
            res.basicRequestList[i] &&
            res.basicRequestList[res.basicRequestList.length - 1] &&
            res.basicRequestList;
        });
      }

      this.departmentOptions = await queryDepartment({ request: {} });
      // console.log(this.departmentOptions, res.orgId);

      this.getDepartment(`${res.orgId}`, this.departmentOptions);
    },
    getSubDepartmentTreeData(parentId, data) {
      const res = findTreeElement(parentId, data);

      return res;
    },

    getDepartment(id) {
      const res = this.getSubDepartmentTreeData(id, this.departmentOptions);
      // console.log(res);

      if (res) {
        this.departPeopleCount = res.countNumber;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.link-text {
  // white-space: nowrap;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    color: #66b1ff;
  }
}
.assessBox .app-container .viewbox .level_box_now {
  font-weight: bold;
}
.inputDisable {
  background: #f5f7fa;
  color: #c0c4cc;
  cursor: not-allowed;
}
.assessBox {
  .app-container {
    color: #484848;
    padding: 0;
    background-color: #f6f7fb;
    .lastone {
      margin: 0px 0 24px;
    }
    .titlebox {
      text-align: center;
      line-height: 17px;
      background: #fff;
      font: bold 18px/25px "Microsoft YaHei";
      padding: 14px 40px;

      > .el-button {
        float: left;
        color: #484848;
        transform: translateY(-8px);
        font-size: 16px;
      }
      text-align: center;
      line-height: 17px;
      font: bold 18px/25px "Microsoft YaHei";
      margin-bottom: 20px;
    }
    .viewbox {
      margin: 0 20px;
      background: #ffffff;
      min-height: 300px;
      padding: 50px;
    }
  }
}
.baseWarp {
  display: flex;
  padding-left: 28px;
  .baseBox {
    width: calc(100% / 2);
    // height: 100px;
    line-height: 40px;
    font-size: 14px;
    color: #2B3642;
    .icon-time{
      font-size: 18px;
      padding: 0 6px;
    }
    label {
      font-size: 16px;
      font-weight: normal;
      color: #777e8c;
    }
  }
}
.resultWrap {
  padding: 10px 30px;
}

.information {
  // height: 500px;
  label {
    width: 120px;
    // font-size: 16px;
    text-align: right;
    font-weight: normal;
    display: inline-block;
  }
  .resultTitle {
    padding: 20px 0 0 30px;
    font-size: 22px;
    font-weight: 600;
  }
  .baseWarp {
    display: flex;
  }
  .skill {
    padding: 10px 0;
    display: flex;
  }
}
.w {
  width: calc(100% - 160px);
  margin: 0 auto;
}
.enclosure {
  .content {
    width: calc(100% - 134px);
    margin: 0 auto;
    display: flex;
    padding: 20px 0px;
    label {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      transform: translateY(5px);
      color: #777E8C;
      letter-spacing: 0;
      text-align: right;
    }
    .book {
      padding-bottom: 20px;
      font-size: 14px;
    }
  }
}

.approver {
  .content {
    padding: 20px 0px;
    .cont_t {
      padding-bottom: 20px;
      display: flex;
      overflow: hidden;
      .cont-title {
        width: 115px;
        text-align: right;
        > label {
          font-size: 14px;
          color: #777e8c;
          font-weight: normal;
          vertical-align: middle;
        }
      }
      > .cont-content {
        padding: 0 30px;
        color: #2B3642;
        min-width: 48%;
        float: left;
        .go {
          transform: translateY(1px);
        }
        .go,
        .out {
          vertical-align: middle;
          font-size: 14px;
          display: flex;
          align-content: center;
          padding-bottom: 20px;
          > span {
            padding: 0 40px 0 0px;
            height: 20px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

.title-icon {
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #2B3642;
  &:before {
    display: inline-block;
    width: 8px;
    height: 22px;
    // transform: translateY(1px);
    background: #498df0;
    content: " ";
    border-radius: 5px;
    vertical-align: sub;
    margin-right: 10px;
  }
}
</style>

